#HelpItem {
  position: absolute;
  top: 3rem;
  right: 2rem;
  .help {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    --ion-text-color: var(--ion-color-secondary-text);
    ion-icon {
      font-size: 1.2rem;
      --ion-text-color: var(--ion-color-tertiary);
    }
    #Tooltip {
      cursor: pointer;
    }
  }
}
