@import '../../theme/size.scss';
@import '../../theme/variables.scss';

.InfoBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin: 2rem 0 3rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  border: 2px solid var(--ion-darker-color);
  background-color: var(--ion-toolbar-background);
  min-width: 10rem;

  @media (max-width: $sm) {
    flex-direction: column;
    .iconSide {
      display: none;
    }
  }
  
  ion-icon {
    font-size: 1.5rem;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .info-title {
    font-weight: bold;
    font-size: 1rem;
    padding-bottom: 0.5rem;
    color: var(--ion-darker-color);
  }
  .info-description {
    font-size: 0.9rem;
    color: var(--ion-text-color);
    white-space: pre-line;
  }
  
  .button {
    &.success {
      --ion-color-primary: var(--ion-color-success);
    }
    &.warning {
      --ion-color-primary: var(--ion-color-warning);
    }
    &.info {
      --ion-color-primary: var(--ion-color-info);
    }
    &.danger {
      --ion-color-primary: var(--ion-color-danger);
    }
  }

  &.success {
    --ion-darker-color: var(--ion-color-success);
    --ion-toolbar-background: rgba(var(--ion-color-success-rgb), 0.05);
    ion-icon {
      --ion-text-color: var(--ion-color-success);
    }
  }
  &.warning {
    --ion-darker-color: var(--ion-color-warning);
    --ion-toolbar-background: rgba(var(--ion-color-warning-rgb), 0.05);
    ion-icon {
      --ion-text-color: var(--ion-color-warning);
    }
  }
  &.info {
    --ion-darker-color: var(--ion-color-info);
    --ion-toolbar-background: rgba(var(--ion-color-info-rgb), 0.05);
    ion-icon {
      --ion-text-color: var(--ion-color-info);
    }
  }
  &.danger {
    --ion-darker-color: var(--ion-color-danger);
    --ion-toolbar-background: rgba(var(--ion-color-danger-rgb), 0.05);
    ion-icon {
      --ion-text-color: var(--ion-color-danger);
    }
  }
}
