@import '../../../theme/size.scss';

#MainHeader {
  box-shadow: none;
  --ion-background-color: var(--ion-header-background);
  --ion-text-color: var(--ion-header-text-color);
  color: var(--ion-text-color);

  &::after {
    background-image: none;
  }

  &,
  .headerToolbar {
    min-height: var(--ion-header-size);
    height: var(--ion-header-size);
    background-color: var(--ion-background-color);
  }

  .img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    object-position: left;
  }

  .headerToolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;

    ion-buttons {
      margin: 0;
      min-height: var(--ion-header-size);
      height: var(--ion-header-size);
    }

    .startHeader,
    .middleHeader,
    .endHeader {
      display: flex;
      flex-direction: row;
      min-height: var(--ion-header-size);
      height: var(--ion-header-size);
      align-items: center;
    }

    .startHeader {
      padding: 0rem 1rem;
      min-width: var(--side-min-width);
      width: 100vw;
      max-width: var(--side-max-width);

      &.no-space {
        min-width: unset;
        width: auto;
        max-width: unset;
      }

      @media (max-width: $sm) {
        min-width: unset;
        width: auto;
        max-width: unset;
        background-color: unset;
        ion-label {
          display: none;
        }
      }
    }

    .middleHeader {
      flex: 1;
      justify-content: flex-start;

      @media (max-width: $sm) {
        &.space {
          padding-left: 1rem;
        }
      }
      .headerItem {
        cursor: pointer;
        padding: 0 1rem;
        @media (max-width: $md) {
          padding: 0 0.75rem;
          &:first-child {
            padding-left: 1.5rem;
          }
        }
        @media (max-width: $sm) {
          &:first-child {
            padding-left: 0rem;
          }
        }
      }

      ion-label {
        padding-left: 0.5rem;
        width: 100%;
      }

      @media (max-width: $md) {
        ion-label {
          display: none;
        }
      }
    }
    .endHeader {
      padding: 0rem 1.5rem;
      justify-content: flex-end;
      gap: 1rem;

      .UserDefaultProfilePicture {
        width: 1.75rem;
        height: 1.75rem;
      }
    }

    ion-item {
      overflow: visible;
      --padding-start: unset;
      --padding-end: unset;
      --inner-padding-start: unset;
      --inner-padding-end: unset;
    }

    .headerToggle {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      user-select: none;
      width: 100%;
      height: 100%;
      --min-height: var(--ion-header-size);
      --ion-background-color: var(--ion-header-background);
      padding: 0.25rem 0;

      &.toggle {
        cursor: pointer;
      }
      &.menu-toggle-hidden {
        display: none;
      }

      .company {
        display: flex;
        align-items: center;
        flex-direction: row;
      }
      .image {
        display: flex;
        align-items: center;
        flex-direction: row;
        img {
          height: 2.5rem;
          object-fit: contain;
        }
      }

      ion-label {
        padding-left: 0.5rem;
      }

      ion-label,
      ion-icon {
        font-size: 1.3rem;
      }
    }
  }
}

#LogoutModal {
  --border-radius: 0.25rem;
  --width: auto;
  --height: auto;
  --min-width: 400px;

  @media (max-width: $sm) {
    --min-width: 90vw;
    --max-width: 90vw;
  }

  #ion-react-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    padding: 2rem;
    .title {
      font-weight: bold;
      margin-bottom: 0.5rem;
      color: var(--ion-title-color);
    }
    .message {
      margin-bottom: 2rem;
    }

    .buttonsBox {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;
      width: 100%;

      .button:first-child {
        margin-right: 0.5rem;
      }
      .button:not(:first-child) {
        margin-left: 0.5rem;
      }
    }
  }
}
