.UserDefaultProfilePicture {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;

  width: 1.5rem;
  height: 1.5rem;
  color: white;

  .UserProfilePicture {
    object-fit: cover;
    padding-right: 0.5rem;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &.icon {
    cursor: auto;
    background-color: rgba(var(--ion-color-secondary-rgb), .75);
    ion-icon {
      font-size: unset !important;
      height: 75%;
      width: 75%;
    }
  }

  & .InitialsPicture {
    user-select: none;
    text-transform: uppercase;
    font-size: 0.8rem;
    line-height: 1;
  }
}
