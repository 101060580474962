@import '../../../theme/size.scss';

#DemoModal {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 1rem;
  z-index: 100;

  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-color: var(--ion-background-color);
  overflow: hidden;
  min-width: 25rem;
  max-width: 30rem;
  box-shadow: 0px 0px 0.5rem 0.125rem var(--ion-box-shadow-color);

  @media (max-width: $sm) {
    left: 1rem;
    min-width: unset;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.75rem;
    font-size: 0.9rem;
    background-color: rgba(var(--ion-color-primary-rgb), 0.05);
    cursor: pointer;

    ion-icon {
      font-size: 1.2rem;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    background-color: var(--ion-disabled-background);

    transition: all 0.5s 0s ease;
    &.true {
      height: 50vh;
      min-height: 25rem;
    }
    &.false {
      height: 0vh;
      min-height: 0rem;
    }
  }

  .filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.75rem;
    border-bottom: var(--ion-border);
    gap: 1rem;
    .dropdownPicker {
      display: flex;
      flex-direction: column;
      flex: 1;

      .selector {
        margin: 0;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0.75rem;
    gap: 1rem;
    overflow: auto;

    .roleItem {
      .head {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        .label {
          font-size: 0.9rem;
          color: var(--ion-text-color);
        }

        .add {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;
          --ion-text-color: var(--ion-color-info);
          color: var(--ion-color-info);
          font-size: 0.9rem;
        }
      }

      .box {
        display: flex;
        flex-direction: column;
        padding: 1rem 0rem;
        gap: 0.5rem;
      }

      .nothing {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: 0.8rem;
        color: var(--ion-text-color);
        font-style: italic;
      }

      .userItem {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.75rem;
        font-size: 0.9rem;
        padding: 0.25rem;

        &.true {
          background-color: rgba(var(--ion-color-primary-rgb), 0.1);
        }

        .UserDefaultProfilePicture {
          width: 2rem;
          height: 2rem;
          border: 1.6px solid #fff;
          .InitialsPicture {
            font-size: 0.7rem;
          }
        }

        .label {
          font-size: 0.9rem;
          display: flex;
          flex: 1;
          color: var(--ion-text-color);
        }
        ion-icon {
          min-height: 100%;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 0.75rem;
    border-top: var(--ion-border);
    .button:not(.filled) {
      background-color: #fff;
    }
  }
}
.DemoModalWarning #ion-react-wrapper .warningContent .message {
  &.warning_message {
    text-align: left;
  }
}
