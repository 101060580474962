#Tooltip {
  position: relative;
  display: flex;

  &.column {
    flex-flow: column;
  }

  .toolTipIcon {
    font-size: 1.2rem;
    --ion-text-color: var(--ion-darker-color);
  }

  .tooltipInfo {
    position: absolute;
    right: 0;
    bottom: calc(100% + 0.5rem);

    font-size: 0.9rem;
    border-radius: 0.25rem;
    color: var(--ion-invert-text-color);
    background-color: var(--ion-darker-color);

    max-height: 0;
    max-width: 0;
    opacity: 0;
    display: none;
    white-space: pre-wrap;
    transition: opacity 0.25s 0s ease;
  }

  .right {
    right: unset;
    left: 0;
  }
  .left {
    right: 0;
    left: unset;
  }
  .center {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
  }
  .top {
    top: unset;
    bottom: calc(100% + 0.5rem);
  }
  .bottom {
    top: calc(100% + 0.5rem);
    bottom: unset;
  }

  &:hover {
    .tooltipInfo {
      opacity: 1;
      display: block;
      max-height: auto;
      max-width: auto;
      max-height: 100vh;
      min-width: 20rem;
      min-width: fit-content;
      white-space: nowrap;
      word-break: keep-all;
      // max-width: 20rem;
      padding: 0.5rem 0.75rem;
      z-index: 100000;
    }
  }
}
