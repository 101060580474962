/* Ionic Variables and Theming. For more info, please see: http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-mobile-size: 576px;
  --ion-md-size: 850px;

  /** custom **/
  --ion-header-size: 3.5rem;
  --ion-title-color: #3e4359;
  --ion-invert-text-color: #fff;
  --ion-border-color: #dde3f6;
  --ion-border-secondary-color: #9ba3cc;
  --ion-border: 1px solid var(--ion-border-color);
  --ion-darker-border-color: #ced6f2;
  --ion-box-shadow-color: rgba(0, 0, 0, 0.1);
  --ion-box-shadow: 0px 0px 10px 5px var(--ion-box-shadow-color);

  /** tables **/
  --ion-table-header-background: var(--ion-color-secondary);
  --ion-table-header-text: var(--ion-color-secondary-contrast);

  /** dark **/
  --ion-dark-color: #afafaf;
  --ion-darker-color: #707070;

  /** disabled **/
  --ion-disabled-color: #838383;
  --ion-disabled-color-rgb: 131, 131, 131;
  --ion-disabled-background: #ebedfa;

  /** backgrounds **/
  --ion-header-text-color: #fff;
  --ion-header-background: var(--ion-color-primary);
  --ion-background-color: #ffffff;
  --ion-toolbar-background: rgba(var(--ion-color-primary-rgb), .15);
  --ion-toolbar-child: rgba(var(--ion-color-primary-rgb), .05);

  --ion-text-default: #3e4359;
  --ion-text-black: #000;

  /** primary **/
  --ion-text-color: #3e4359;
  --ion-text-color-rgb: 62, 67, 89;
  --ion-color-primary: #124f76;
  --ion-color-primary-rgb: 18, 79, 118;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #082334;
  --ion-color-primary-tint: #1c7bb8;

  /** secondary **/
  --ion-color-secondary: #78aed0;
  --ion-color-secondary-rgb: 120, 174, 208;
  --ion-color-secondary-text: #636a8f;
  --ion-color-secondary-text-rgb: 99, 106, 143;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #418cbb;
  --ion-color-secondary-tint: #b1d0e4;

  /** tertiary **/
  --ion-color-tertiary: #e60064;
  --ion-color-tertiary-rgb: 230, 0, 100;
  --ion-color-tertiary-text: #9ba3cc;
  --ion-color-tertiary-text-rgb: 155, 163, 204;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #9a0043;
  --ion-color-tertiary-tint: #ff348c;

  /** info **/
  --ion-color-info: #6d96f7;
  --ion-color-info-rgb: 109, 150, 247;
  --ion-color-info-contrast: #ffffff;
  --ion-color-info-contrast-rgb: 255, 255, 255;
  --ion-color-info-shade: #3171e0;
  --ion-color-info-tint: #f7faff;

  /** success **/
  --ion-color-success: #52cca3;
  --ion-color-success-rgb: 82, 204, 163;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #30a27b;
  --ion-color-success-tint: #8dddc2;

  /** warning **/
  --ion-color-warning: #f5b862;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #f0971a;
  --ion-color-warning-tint: #fad9aa;

  /** danger **/
  --ion-color-danger: #f77563;
  --ion-color-danger-rgb: 247, 117, 99;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #f3351a;
  --ion-color-danger-tint: #fbb5ac;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** For default **/
  --ion-default-primary: #124f76;
  --ion-default-secondary: #78aed0;
  --ion-default-tertiary: #e60064;
}
