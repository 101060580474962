@import '../../../../../theme/size.scss';

.CreatePasswordModal {
  --border-radius: 0.25rem;
  --width: auto;
  --height: auto;

  @media (max-width: $sm) {
    --min-width: 95vw;
    --max-width: 95vw;
  }

  #ion-react-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .close {
      position: fixed;
      top: 1rem;
      right: 1rem;
      font-size: 1.25rem;
      cursor: pointer;
    }

    .box {
      padding: 1rem;
    }

    .img {
      padding: 1rem;
      min-height: 15rem;
      height: 35vh;
      max-height: 20rem;
      @media (max-width: 1000px) {
        max-height: 10rem;
        min-height: 10rem;
        padding: 1rem;
      }
    }

    .title {
      padding: 1.5rem;
      font-size: 1.5rem;
      color: var(--ion-title-color);
      width: 100%;
      border-bottom: var(--ion-border);
    }

    .buttonBox {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;
      width: 100%;
      margin-top: 2rem;
      gap: 1rem;
    }

    .password-strength-bar {
      width: 100%;
      height: 15px;
      background-color: #e0e0e0;
      border-radius: 5px;
      margin-top: 1rem;
      overflow: hidden;
      position: relative;
    }

    .password-strength-bar-fill {
      height: 100%;
      transition: width 0.5s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 0.75rem;
      font-weight: bold;
    }

    .password-strength-text {
      position: absolute;
      width: 100%;
      text-align: center;
    }
  }
}
