@import '../../theme/size.scss';
@import '../../theme/variables.scss';

.WarningModal {
  --border-radius: 1rem;
  --width: auto;
  --height: auto;
  --min-width: 600px;
  --max-width: 90vw;
  --box-shadow: var(--ion-box-shadow) !important;
  --backdrop-opacity: .2 !important;

  @media (max-width: $sm) {
    --min-width: 90vw;
    --max-width: 90vw;
  }

  #ion-react-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .warningHeader {
      min-height: 3rem;
      padding: 1.5rem;
      padding-bottom: 0.875rem;
      border-bottom: 1px var(--ion-disabled-background) solid;
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 1.5rem;
        font-weight: 300;
        color: var(--ion-title-color);
      }
      .icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
    .warningContent {
      padding: 28px 72px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      .img {
        height: 198px;
        width: 228px;
        align-self: center;
        margin-bottom: 27px;
      }
      .title {
        font-weight: 500;
        line-height: 1.4;
        margin-bottom: 1rem;
        color: var(--ion-title-color);
        font-size: 1rem;
      }
      .message {
        margin-bottom: 1.5rem;
        white-space: pre-wrap;
        color: var(--ion-title-color);
        font-size: 0.875rem;
        text-align: center;
        line-height: 1.6;
        max-width: 600px;

        &.bold {
          font-weight: bold;
        }
      }
      .paragraph {
        margin-bottom: 0.75rem;
        white-space: pre-wrap;
        color: var(--ion-title-color);
        font-size: 0.875rem;
        line-height: 1.6;
      }
      .toLeft {
        text-align: left;
      }
    }
    .buttonsBox {
      padding: 2rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;
      width: 100%;

      .button:first-child {
        margin-right: 0.5rem;
      }
      .button:not(:first-child) {
        margin-left: 0.5rem;
      }
    }
  }
}
