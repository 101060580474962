[id^='react-select-3-option'] {
  &:active {
    background-color: rgba(173, 174, 155, 0.2) !important;
  }
}

.dropdownPicker {
  &.error {
    .label {
      color: var(--ion-color-danger);
    }
    .selector {
      border: 1px solid var(--ion-color-danger);
      >div[class*='menu']{
        border: 1px solid var(--ion-color-danger);
        border-top-width: 0;
        border-bottom-width: 1;
      }
    }
  }
  .selector {
    margin: 0.75rem 0 0;
  }
  .label {
    padding: 0.5rem 0rem 0.1rem;
    font-size: 1rem;
  }
}
