.CustomImageComponent {
  // Main Colors
  --background: var(--ion-toolbar-background);
  --primary: var(--ion-color-primary);
  --secondary: var(--ion-color-secondary);
  --tertiary: var(--ion-color-tertiary);
  --danger: var(--ion-color-danger);
  --success: var(--ion-color-success);
  --warning: var(--ion-color-warning);

  // Text Colors
  --primary-text: var(--ion-text-color);
  --secondary-text: var(--ion-color-secondary-text);
  --tertiary-text: var(--ion-color-tertiary-text);

  // Other colors
  --white: #fff;
  --border: var(--ion-border-color);
  --dark-border: var(--ion-darker-border-color);
  --disabled: var(--ion-disabled-background);

  // Opacity
  --back-opacity: 1;

  &,
  svg,
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
