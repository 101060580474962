#MenuToolbar {
  border: none;
  --background: transparent;
  --ion-background-color: var(--ion-toolbar-background);

  ion-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    height: 3.5rem;
    --ion-background-color: transparent;
    --ion-text-color: var(--ion-color-primary);
    .childDot {
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 0.25rem;
      margin-right: 0.825rem;
      background-color: var(--ion-color-primary);
    }
    &.selected {
      --ion-text-color: var(--ion-color-secondary-contrast);
      background-color: var(--ion-color-secondary);
      .childDot {
        background-color: var(--ion-color-secondary-contrast);
      }
    }
    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
    &.parentOpen {
      background-color: var(--ion-toolbar-child);
    }
  }

  ion-label {
    margin: 0;
    padding: 0 1rem;
  }

  ion-list {
    height: calc(100% - var(--ion-header-size));
    padding-top: 0;
    padding-bottom: 3rem;
    overflow: auto;
    background: transparent;
    ion-item {
      border-bottom: var(--ion-border);
    }
  }

  ion-list,
  .bottom {
    ion-item {
      padding: 0.25rem 0;
    }
  }

  #CompanyLogo {
    cursor: auto;
    user-select: none;
    --min-height: var(--ion-header-size);
    --ion-text-color: var(--ion-header-text-color);
    --ion-background-color: var(--ion-header-background);
  }

  .dpItems {
    height: 0;
    overflow: auto;
    background-color: var(--ion-toolbar-child);
    --ion-background-color: var(--ion-toolbar-child);
    ion-item {
      --min-height: 2.5rem;
      padding: 0.1rem 0;
      padding-left: 4.125rem;
      ion-label {
        padding-left: 0;
      }
    }
  }

  .bottom {
    ion-item {
      border-top: var(--ion-border);
    }
  }
  .bottomLogo {
    padding: 0 !important;
    cursor: pointer;
    user-select: none;
    --min-height: var(--ion-header-size);
    .image {
      display: flex;
      align-items: center;
      flex-direction: row;
      img {
        height: 1.5rem;
        object-fit: contain;
      }
    }
  }
}
