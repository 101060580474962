#VoteElections {
  --padding-bottom: 10rem;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .title,
    &.title {
      color: var(--ion-title-color);
      font-size: 1.3rem;
    }
    ion-icon,
    .back {
      cursor: pointer;
    }

    ion-icon {
      font-size: 1.15rem;
    }
    .back {
      padding: 0 0.5rem 0 0.25rem;
    }
    padding-bottom: 1rem;
  }

  .pageTitle {
    font-size: 1.5rem;
    padding: 1rem 0rem 2rem;
    font-weight: bold;
    color: var(--ion-title-color);
  }

  .message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;

    background-color: rgba(var(--ion-color-info-rgb), 0.05);
    border: 1px solid var(--ion-color-info);

    .info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .title {
        font-weight: bold;
        padding: 0 0 0.5rem;
      }
      .desc {
        line-height: 1.6;
        color: var(--ion-title-color);
        font-size: 0.9rem;
      }
    }

    .title,
    ion-icon {
      font-size: 1.25rem;
      color: var(--ion-color-info);
      --ion-text-color: var(--ion-color-info);
    }

    &.ready {
      background-color: rgba(var(--ion-color-warning-rgb), 0.05);
      border: 1px solid var(--ion-color-warning);
      .title,
      ion-icon {
        color: var(--ion-color-warning);
        --ion-text-color: var(--ion-color-warning);
      }
    }
  }

  .candidates {
    display: flex;
    flex-direction: column;
    padding: 2rem 0rem;
    gap: 1rem;

    .headTitle {
      font-size: 1.25rem;
      font-weight: 500;
      padding: 1rem 1.5rem;
      border-radius: .5rem;
      color: var(--ion-table-header-text);
      background-color: var(--ion-table-header-background);
    }
    .list {
      display: flex;
      flex-direction: column;
      gap: .5rem;
    }
  }

  .footer {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 1.5rem 2rem;
    border-top: var(--ion-border);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;
    background-color: var(--ion-background-color);

    .recap_votes {
      font-size: .85rem;
      text-align: right;
    }

    .buttonsBox {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
    }

    .controleContainer {
      bottom: 3rem;
      font-size: 0.75rem;
      position: absolute;
      bottom: 3rem;
      left: -10rem;
      right: 0px;
      color: var(--ion-color-info);
      display: flex;
      align-items: center;
      justify-content: flex-end;

      cursor: pointer;

      ion-icon {
        font-size: 0.75rem;
        color: var(--ion-color-info);
        --ion-text-color: var(--ion-color-info);
        margin-right: 0.5rem;
      }
    }
  }
}
