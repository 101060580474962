@import "../../../theme/size.scss";

#WasLoggedOut {
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  margin: 0rem 2rem;
  padding: 0.65rem 2rem 0.75rem;

  @media (max-width: $sm) {
    margin: 0rem 1rem;
    padding: 0.65rem 1rem 0.75rem;
  }

  border: 1px solid var(--ion-color-primary);
  border-top: none;
  background-color: rgba(var(--ion-color-primary-rgb), 0.15);
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#IdleModal {
  --border-radius: 0.25rem;
  --width: auto;
  --height: auto;
  --min-width: 400px;

  @media (max-width: $sm) {
    --min-width: 90vw;
    --max-width: 90vw;
  }

  #ion-react-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    padding: 2rem;
    .title {
      font-weight: bold;
      margin-bottom: 0.5rem;
      color: var(--ion-title-color);
    }
    .message {
      margin-bottom: 2rem;
    }

    .buttonsBox {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;
      width: 100%;

      .button:first-child {
        margin-right: 0.5rem;
      }
      .button:not(:first-child) {
        margin-left: 0.5rem;
      }
    }
  }
}
