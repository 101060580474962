#DemoFrame {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  border: .25rem solid var(--ion-color-primary);
  background-color: #FFF;
}
