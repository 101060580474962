#DemoModal {
  .roleItem {
    .roleBox {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding-top: 0.5rem;

      .random {
        & {
          --ion-text-color: var(--ion-invert-text-color);
        }
        cursor: pointer;
        font-size: 1rem;
        padding: 0.575rem;
        border-radius: 0.25rem;
        background-color: var(--ion-color-primary);
        margin-right: 0.5rem;

        &.disabled{
          cursor: not-allowed;
        }
      }

      .dropdownBox {
        display: flex;
        flex: 1;
        flex-direction: column;

        .dropdown {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: 0.1rem;
          border-radius: 0.15rem;
          border: var(--ion-border);
          max-height: 10rem;
          overflow: auto;
          gap: 0.5rem;
          font-size: 0.9rem;
          background-color: #fff;

          &.hidden {
            padding: 0;
            margin: 0;
            max-height: 0;
            border: none;
          }

          .no_data {
            font-size: 0.8rem;
            padding: 0.5rem 0.75rem;
          }
          .userItem {
            width: 100%;
            padding: 0.5rem 0.75rem;
            .label {
              font-size: 0.8rem;
            }
            &:hover {
              background-color: rgba(var(--ion-color-primary-rgb), 0.1);
            }
          }
        }
      }

      .inputBox {
        display: flex;
        flex-direction: row;
        align-items: center;

        background-color: #fff;
        border-radius: 0.15rem;
        border: var(--ion-border);

        .searchIcon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        input {
          display: flex;
          flex: 1;
          border: none;
          outline: none;
        }

        .selectionInput {
          display: flex;
          flex: 1;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 0.5rem;

          .inputUserItem {
            border: 1px solid var(--ion-color-primary);
            padding-right: 0.75rem;
            background-color: rgba(var(--ion-color-primary-rgb), 0.1);
          }
        }

        input {
          font-size: 0.9rem;
          padding: 0.5rem 0.25rem 0.5rem 0rem;
        }

        .search {
          padding: 0 0.5rem;
        }

        .dropdownIcon {
          display: flex;
          cursor: pointer;
          flex-direction: row;
          align-items: center;
          padding: 0.525rem 0.75rem;
          background-color: var(--ion-border-color);

          ion-icon {
            --ion-text-color: var(--ion-darker-color);
          }
        }
      }
    }
  }
}
