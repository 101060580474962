ion-icon {
  color: var(--ion-text-color);
  stroke: var(--ion-text-color);

  &.disabled {
    color: var(--ion-color-light-shade);
    stroke: var(--ion-color-light-shade);
    cursor: not-allowed;
  }
}
