.button {
  // Button Main Colors
  --ion-color-primary: var(--ion-color-tertiary);
  --ion-color-primary-rgb: var(--ion-color-tertiary-rgb);
  --ion-background-color: var(--ion-color-tertiary-contrast);
  --ion-invert-text-color: var(--ion-color-tertiary-contrast);

  // Style
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;

  padding: 0.5rem 0.75rem;
  color: var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary);
  border-radius: 0.25rem;
  transition: all 0.25s 0s ease;
  font-size: 0.9rem;
  font-weight: bold;
  min-height: 40px;
  background-color: var(--ion-background-color);

  cursor: pointer;

  &:focus,
  &:active:not(.disabled) {
    background-color: rgba(var(--ion-color-primary-rgb), 0.5);
  }

  ion-icon {
    padding-right: 0.5rem;
    --ion-text-color: var(--ion-color-primary);
  }

  &.transparent {
    background-color: transparent;
  }

  &.filled {
    background-color: var(--ion-color-primary);
    color: var(--ion-invert-text-color);
    ion-icon {
      --ion-text-color: var(--ion-invert-text-color);
    }
  }

  &.disabled {
    transform: none;
    opacity: 0.125;
    border: 1px solid transparent;
    color: var(--ion-invert-text-color);
    background-color: var(--ion-darker-color);
    cursor: not-allowed;
    ion-icon {
      --ion-text-color: var(--ion-invert-text-color);
    }
  }

  &.danger {
    border: 1px solid var(--ion-color-danger);

    background-color: var(--ion-color-danger);
    color: var(--ion-invert-text-color);
    font-size: 0.875rem;
    font-weight: normal;
    ion-icon {
      --ion-text-color: var(--ion-invert-text-color);
    }
    &.disabled {
      background-color: var(--ion-disabled-background);
    }
    &:focus,
    &:active:not(.disabled) {
      background-color: rgba(var(--ion-color-danger-rgb), 0.5);
    }
  }

  // --ion-color-success: #52cca3;
  // --ion-color-success-rgb: 82, 204, 163;
  // --ion-color-success-contrast: #ffffff;
  // --ion-color-success-contrast-rgb: 255, 255, 255;
  // --ion-color-success-shade: #30a27b;
  // --ion-color-success-tint: #8dddc2;

  &.success {
    border: 1px solid var(--ion-color-success);
    background-color: var(--ion-color-success);
    color: var(--ion-invert-text-color);
    font-size: 0.875rem;
    font-weight: normal;
    ion-icon {
      --ion-text-color: var(--ion-invert-text-color);
    }
    &.disabled {
      background-color: var(--ion-disabled-background);
    }
    &:focus,
    &:active:not(.disabled) {
      background-color: rgba(var(--ion-color-success-contrast-rgb), 0.5);
    }
  }

  &.nothing {
    border: 1px solid transparent;
    color: var(--ion-color-info);
    font-size: 0.875rem;
    font-weight: normal;
    ion-icon {
      --ion-text-color: var(--ion-color-info);
    }
    &.disabled {
      background-color: var(--ion-disabled-background);
    }
    &:focus,
    &:active:not(.disabled) {
      background-color: rgba(var(--ion-color-info-rgb), 0.3);
    }
  }
  &.accent {
    background-color: var(--ion-color-tertiary);
    border-color: var(--ion-color-tertiary);
    color: white;
    &:focus,
    &:active:not(.disabled) {
      background-color: rgba(var(--ion-color-tertiary-rgb), 0.5);
    }
  }
}
