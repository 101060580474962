@import '../../theme/size.scss';
@import '../../theme/variables.scss';

#LegalModal {
  --border-radius: 1rem;
  --width: 80%;
  --height: 100%;
  --max-height: 80vh;
  --box-shadow: var(--ion-box-shadow) !important;
  --backdrop-opacity: 0.2 !important;

  @media (max-width: $sm) {
    --min-width: 90vw;
    --max-width: 90vw;
  }

  .legalContent {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;

    h2 {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 1.2rem;
      color: #0056b3;
    }

    p {
      margin-bottom: 15px;
      text-align: justify;
    }

    strong {
      font-weight: bold;
    }
  }
  .icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .ion-modal {
    .ion-header {
      --background: #f8f9fa;
      --border-color: #dee2e6;
      --color: #333;
    }

    .ion-title {
      font-size: 1.4rem;
      font-weight: 500;
    }

    .ion-toolbar {
      --background: #f8f9fa;
    }

    .ion-button {
      --background: none;
      --color: #333;
      --padding-start: 0;
      --padding-end: 0;
    }

    .legalContent {
      padding: 16px;
      background: #ffffff;
      border-radius: 8px;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    }

    /* Large modal adjustments */
    &.ion-modal {
      --width: 90%;
      --max-width: 800px;
      --height: 80%;
      --max-height: 90%;
    }
  }
}
