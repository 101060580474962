.AssistanceFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  min-height: 2.25rem;
  padding: 0.5rem 2rem;
  order: 1;
  width: 100%;
  z-index: 10;
  background-color: var(--ion-disabled-background);
  font-size: 0.875rem;
  color: var(--ion-color-primary);
  .serverDateContainer {
    display: flex;
    flex-flow: column;
    flex-flow: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .datetime {
    margin: 0 5px;
  }

  .pointer {
    cursor: pointer;
  }

  .pointer {
    cursor: pointer;
  }
}
