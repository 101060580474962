.myCurrentPage {
  background-color: var(--ion-background-color);
  .page {
    --padding-top: 2.5rem;
    --padding-bottom: 2.5rem;
    --padding-start: 1.5rem;
    --padding-end: 1.5rem;
  }
}

.addBottomSpace {
  padding-bottom: 2.25rem;
  margin-bottom: 2.25rem;
}

.pageClass {
  flex: 1;
  display: flex;
  border: none;

  --side-max-width: 100vw;

  .ion-page > ion-content {
    --background: transparent;
  }
}
