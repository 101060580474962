.headerSpace {
  margin-top: var(--ion-header-size);
}

ion-split-pane {
  width: 100vw;
  --side-min-width: 270px;
  --side-max-width: 25%;
  flex-direction: row;
}

input[type='time'],
input[type='date'] {
  width: 11.25rem;
  border: none;
  clip-path: inset(0 17px 0 0);
  outline: none;
  outline: 0;
  padding: 0;
}

input[type='time']::-webkit-calendar-picker-indicator {
  background: none;
  border: none;
  outline-width: 0;
}

ion-modal.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default {
  --backdrop-opacity: 0.2;
  --box-shadow: var(--ion-box-shadow);
}

body {
  --side-min-width: 270px;
  --side-max-width: 25%;
}

.MainAppLoader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.7);
}

ion-spinner {
  color: var(--ion-color-primary);
  height: 5rem;
  width: 5rem;
}

html.plt-mobile ion-app {
  user-select: auto;
}

.backgroundBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
}

ion-app {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);

  margin-bottom: constant(safe-area-inset-top);
  margin-bottom: env(safe-area-inset-bottom);
}