.loaderBox {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ion-spinner {
    height: 5rem;
    width: 5rem;
  }
  &.fullscreen {
    height: 100vh;
  }
}
