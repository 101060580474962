@import '../../theme/size.scss';
@import '../../theme/variables.scss';

.VoteInfoConfirmationModal {
  --border-radius: 1rem;
  --width: auto;
  --height: auto;
  --min-width: 600px;
  --max-width: 90vw;
  --box-shadow: var(--ion-box-shadow) !important;
  --backdrop-opacity: 0.2 !important;

  @media (max-width: $sm) {
    --min-width: 90vw;
    --max-width: 90vw;
  }
  .message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;

    background-color: rgba(var(--ion-color-info-rgb), 0.05);
    border: 1px solid var(--ion-color-info);

    .info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .title {
        padding: 0 0 0.5rem;
      }
      .desc {
        font-size: 0.9rem;
      }
    }

    .iconSide {
      display: flex;
      ion-icon {
        font-size: 1.25rem;
        color: var(--ion-color-info);
        --ion-text-color: var(--ion-color-info);
      }
    }

    &.ready {
      background-color: rgba(var(--ion-color-warning-rgb), 0.05);
      border: 1px solid var(--ion-color-warning);
      .title,
      ion-icon {
        color: var(--ion-color-warning);
        --ion-text-color: var(--ion-color-warning);
      }
    }
  }
  #ion-react-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .warningHeader {
      min-height: 3rem;
      padding: 1.5rem;
      padding-bottom: 0.875rem;
      border-bottom: 1px var(--ion-disabled-background) solid;
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 1.5rem;
        font-weight: 300;
        color: var(--ion-title-color);
      }
      .icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
    .warningContent {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;

      .fieldContainer {
        margin-bottom: 1.5rem;
        display: flex;
        flex-direction: column;
        width: 100%;
        .textInputBox {
          .inputHeader {
            .label {
              color: var(--ion-text-color);
              font-size: .75rem;
              padding: 0 !important;
            }
          }
          .textInputGroup {
            margin-top: 4px;
          }
        }
      }
      .img {
        height: 180px;
        align-self: center;
        margin-bottom: 2.5rem;
        margin-top: 41px;
      }
      .title {
        font-weight: 500;
        line-height: 1.4;
        margin-bottom: 1rem;
        color: var(--ion-title-color);
        font-size: 1rem;
      }
      
    }
    .buttonsBox {
      padding: 0 2rem 2rem 2rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;
      width: 100%;
    }
  }
}
