#VoteElectionCandidate {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-height: 4rem;
  border-radius: 0.5rem;
  border: var(--ion-border);
  padding: 0.5rem 1.5rem;
  background-color: #fff;
  transition: all 0.25s 0s ease;
  gap: 1rem;

  &.disabled:not(.selected) {
    cursor: not-allowed;
    .info,
    .candidatePhoto,
    .selectBox {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &.isPreview {
    cursor: auto;
  }

  &.locked {
    cursor: auto;
    background-color: rgba(var(--ion-color-secondary-rgb), 0.1);

    &:not(.selected) {
      .name,
      .description,
      .candidatePhoto {
        opacity: 0.25;
      }
    }
  }

  .checked {
    padding: 0.5rem 0;
    --ion-text-color: var(--ion-color-success);
    font-size: 2rem;
    min-width: 1.25rem;
  }

  #CheckBox {
    padding: 1rem 0rem;
  }

  .candidatePhoto {
    border-radius: 1.5rem;
    height: 3rem;
    width: 3rem;
    min-height: 3rem;
    min-width: 3rem;
    margin: 0 0.5rem;
  }

  .info {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .name {
    flex: 1;
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 1rem 0rem;
    color: var(--ion-title-color);
  }

  .description {
    font-size: 0.9rem;
    line-height: 1.5;
    padding-bottom: 1rem;
  }

  &.whiteVote {
    align-items: center;
    margin-top: 2rem;
    .name {
      font-size: 1.25rem;
    }

    .selectBox {
      border-radius: 50%;
    }
  }

  .rightSide {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    font-size: 1.5rem;
    padding: 1rem 0rem;

    ion-icon {
      cursor: pointer;
    }
  }
}
