#CheckBox {
  padding: 0.5rem 0rem 0.5rem 0;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;

  &.blocked {
    opacity: 0.5;
    cursor: auto;
    &.true {
      opacity: 0.7;
    }
  }

  .label {
    font-size: .8rem;
    padding-left: 0.75rem;
    line-height: 1.5;
    &.selected {
      color: var(--ion-text-color);
    }
  }

  .selectBox {
    height: 1.25rem;
    width: 1.25rem;
    min-height: 1.25rem;
    min-width: 1.25rem;

    transition: all .25s 0s ease;
    border: 2px solid var(--ion-border-color);
    border-radius: 0.1rem;
    background-color: var(--ion-background-color);
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    cursor: pointer;

    &.blocked {
      cursor: auto;
      // border: 1px solid #000;
      // background-color: #000;

      &.true {
        opacity: 1;
      }
    }

    &.true:not(.icon) {
      border: 6px inset var(--ion-color-primary);
    }
    &.true.icon {
      background-color: var(--ion-color-primary);
      border: 1px solid var(--ion-color-primary);
    }

    ion-icon {
      font-size: 1rem;
      --ion-text-color: #FFF;
    }
  }

  &.isError {
    &,
    .label {
      color: var(--ion-color-danger);
    }

    .selectBox{
      border: 1px solid var(--ion-color-danger);
    }
  }

  .errorMessage {
    position: absolute;
    bottom: -1rem;
    right: 0px;
    line-height: 1rem;
    font-size: 0.8rem;
  }
}
